import { Provider } from './provider';
import { Observable } from 'rxjs';
import { GridData } from '../common/gridData';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { Company, RequestResponse } from './company';
import { Address } from './address';
import { Order } from './orders';

export interface QuoteProvider {
  id: number;
  codIntegration: number;
  numQuote: string;
  groupCompany: string;
  dateCreation: Date;
  buyerGroup: string;
  coinId: string;
  dateLimit: Date;
  dateStart: Date;
  paymentConditionId: string;
  observation: string;
  status: string;
  freight: number;
  formPayment: string;
  companyBillingId: string;
  companyDeliveryId: string;
  companyBilling: Company;
  companyDelivery: Company;
  provider: Provider;
  providerId: number;
  quoteId: number;
  order: Order;
}

export interface QuoteProviderResponse { 
  quoteId: number;
  status: string;
  mensage: string;
}

export abstract class QuoteProviderData {
  abstract get gridDataSource(): DataSource;
  abstract getDataSourcePaging(pageNumber: number): DataSource;
  abstract list(pageNumber: number, pageSize: number): Observable<GridData<QuoteProvider>>;
  abstract get(id: number): Observable<QuoteProvider>;
  abstract update(request: QuoteProvider): Observable<RequestResponse>;
  abstract editByResponseProvider(id: number, paymentConditionId: number, formPayment: string, freight: number): Observable<RequestResponse>;
  abstract create(request: QuoteProvider): Observable<RequestResponse>;
  abstract delete(id: number): Observable<boolean>;
  abstract participateQuote(id: number, idStatus: number): Observable<RequestResponse>;
  abstract declineQuote(id: number): Observable<RequestResponse>;
  abstract finishQuote(id: number): Observable<RequestResponse>;
  abstract downloadItens(id: number, fieldSort: string, fieldDirection: string): Observable<any>;
  abstract uploadItens(file: any): Observable<any>;
  abstract getStatusQuote(id: number): Observable<string>;

}

