import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BrandData, Brands } from '../../../interfaces/portal/brands';
import { BrandApi } from '../api/brand.api';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';

@Injectable()
export class BrandService implements BrandData {

  constructor(private api: BrandApi) { }

  get gridDataSource(): DataSource {
    return this.api.usersDataSource;
  }

  list(): Observable<Array<Brands>> {
    return this.api.list();
  }
}
