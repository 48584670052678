import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { Observable } from 'rxjs';

export interface Brands {
    id: number;
    name?: string;
    codIntegration: number;
    codBrand: number;
  }
  
export abstract class BrandData {
  abstract get gridDataSource(): DataSource;
  abstract list(): Observable<Array<Brands>>;
}