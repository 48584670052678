import { Company, RequestResponse } from './company';

import { Observable } from 'rxjs';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { GridData } from '../common/gridData';
import { UnitMeasurement } from './unit-measurement';
import { QuoteProviderItem } from './quote-provider-item';

export interface OrderItem {
    id: number,
    numOrdemItem: string,
    itemId: number,
    quoteProviderId: number,
    companyDelivery: Company,
    companyDeliveryId: string,
    observation: String,
    description: String,
    deliveryDeadline: number,
    deliveryDate: Date,
    unitMeasurement: UnitMeasurement,
    quantity: number,
    unitMeasurementId: string,
    finishPrice: number,
    itemIPI: number,
    status: string,
    ncm: string,
    materialSource: string,
    brands: Array<any>,
    freight: number,
    discount: number,
    brand: string,
    imported: boolean,
    quoteProviderItemId: number,
    quoteProviderItem: QuoteProviderItem
}

export abstract class OrderItemData {
  abstract get gridDataSource(): DataSource;
  abstract list(): Observable<GridData<OrderItem>>;
  abstract get(id: number): Observable<OrderItem>;
}
