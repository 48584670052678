import { Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-dialog-delete-user',
  templateUrl: 'dialog-delete-user.component.html',
  styleUrls: ['dialog-delete-user.component.scss'],
})
export class DialogDeleteUserComponent {
  mensagem: string = 'Você tem certeza que deseja desativar este usuário? Esta é uma ação permanente e irreversível!';

  constructor(protected ref: NbDialogRef<DialogDeleteUserComponent>) {}

  cancel() {
    this.ref.close(false);
  }

  submit() {
    this.ref.close(true);
  }
}
