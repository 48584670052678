
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NB_AUTH_OPTIONS, NbAuthService, NbAuthResult } from '@nebular/auth';
import { getDeepFromObject } from '../../helpers';
import { EMAIL_PATTERN } from '../constants';
import { NbThemeService } from '@nebular/theme';

@Component({
  selector: 'ngx-request-password-page',
  styleUrls: ['./request-password.component.scss'],
  templateUrl: './request-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NgxRequestPasswordComponent implements OnInit {
  redirectDelay: number = 6000;
  showMessages: any = this.getConfigValue('forms.requestPassword.showMessages');
  strategy: string = this.getConfigValue('forms.requestPassword.strategy');
  isEmailRequired: boolean = this.getConfigValue('forms.validation.email.required');
  logo: string = this.changeLogo(this.themeService.currentTheme);

  submitted = false;
  errors: string[] = [];
  messages: string[] = [];
  user: any = {};
  requestPasswordForm: FormGroup;

  constructor(
    protected service: NbAuthService,
    @Inject(NB_AUTH_OPTIONS) protected options = {},
    protected cd: ChangeDetectorRef,
    protected fb: FormBuilder,
    protected router: Router,
    protected themeService: NbThemeService) { }

  get email() { return this.requestPasswordForm.get('email'); }

  ngOnInit(): void {
    const passwordValidators = [
      Validators.required,
      Validators.maxLength(50)
    ];

    this.requestPasswordForm = this.fb.group({
      email: this.fb.control('', [...passwordValidators]),
    });
  }

  requestPass(): void {
    this.user = this.requestPasswordForm.value;
    this.errors = this.messages = [];
    this.submitted = true;

    this.service.requestPassword(this.strategy, this.user).subscribe((result: NbAuthResult) => {
      this.submitted = false;
      if (result.isSuccess()) {
        this.messages = result.getMessages();
      } else {
        this.errors = result.getErrors();
      }

      const redirect = result.getRedirect();

      if (redirect) {
        setTimeout(() => {
          return this.router.navigateByUrl(redirect);
        }, this.redirectDelay);
      }

      this.cd.detectChanges();
    });
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }

  changeLogo(themeName: string) : string {
    return themeName === 'default' ?
        "../../../../assets/images/logo.fw.png" :
        "../../../../assets/images/logo_white.fw.png";
}
}
