<nb-card>
  <nb-card-header>
    <h5 class="grid-h" style="line-height: 40px;">{{ mensagem }}</h5>
  </nb-card-header>
  <nb-card-body>
    <ng2-smart-table
      class="request-smart-table"
      [settings]="settings"
      [source]="source"
      (edit)="onEdit($event)">
    </ng2-smart-table>
  </nb-card-body>
  <nb-card-footer>
    <button class="cancel" nbButton status="danger" (click)="cancel()">Cancelar</button>
  </nb-card-footer>
</nb-card>
