import { QuoteProvider } from './quote-provider';

import { Observable } from 'rxjs';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { GridData } from '../common/gridData';
import { UnitMeasurement } from './unit-measurement';
import { Address } from './address';
import { Item } from './item';
import { Provider } from './provider';
import { Quote } from './quote';
import { Company, RequestResponse } from './company';
import { QuoteItem } from './quote-item';
import { OrderItem } from './orders-item';

export interface QuoteProviderItem {
  id: number,
  numQuoteItem: string,
  itemId: number,
  brandIdSelect: number,
  quoteProviderId: number,
  companyDelivery: Company,
  companyDeliveryId: string,
  observation: String,
  description: String,
  deliveryDeadline: number,
  deliveryDate: Date,
  unitMeasurement: UnitMeasurement,
  quantity: number,
  unitMeasurementId: string,
  priceItem: number,
  itemIPI: number,
  status: string,
  ncm: string,
  materialSource: string,
  brands: Array<any>,
  freight: number,
  discount: number,
  imported: boolean,
  customBrand: string,
  quoteItem?: QuoteItem,
  orderItem: OrderItem
}

export interface RequestNavegation { 
  nextId: number,
  nextCod: string,
  priviousId: number,
  priviousCod: string,
}

export abstract class QuoteProviderItemData {
  abstract get gridDataSource(): DataSource;
  abstract list(): Observable<GridData<QuoteProviderItem>>;
  abstract get(id: number): Observable<QuoteProviderItem>;
  abstract update(registeData: QuoteProviderItem): Observable<RequestResponse>;
  abstract create(registeData: QuoteProviderItem): Observable<RequestResponse>;
  abstract delete(id: number): Observable<boolean>;
  abstract decline(id: number): Observable<RequestResponse>;
  abstract reopen(id: number): Observable<RequestResponse>;
  abstract getBackAndNextIdItemQuote(idQuote: string, idQuoteItem: string, fieldSort: string, fieldDirection: string): Observable<RequestNavegation>;
}
