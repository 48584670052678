import { QuoteItemApi } from './api/quote-item.api';
import { CoinApi } from './api/coin.api';
import { PaymentConditionApi } from './api/payment-condition.api';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonBackendModule } from '../common/common-backend.module';

import { OrderTypesApi } from './api/order-types.api';
import { OrderStatusesApi } from './api/order-statuses.api';
import { OrdersApi } from './api/orders.api';
import { OrdersAggregatedApi } from './api/orders-aggregated.api';
import { OrdersProfitApi } from './api/orders-profit.api';
import { TrafficAggregatedApi } from './api/traffic-aggregated.api';
import { UserActivityApi } from './api/user-activity.api';

import { CountryOrderData } from '../../interfaces/portal/country-order';
import { OrderTypeData } from '../../interfaces/portal/order-type';
import { OrderStatusData } from '../../interfaces/portal/order-status';
import { OrderData } from '../../interfaces/portal/orders';
import { OrdersProfitChartData } from '../../interfaces/portal/orders-profit-chart';
import { ProfitBarAnimationChartData } from '../../interfaces/portal/profit-bar-animation-chart';
import { StatsBarData } from '../../interfaces/portal/stats-bar';
import { StatsProgressBarData } from '../../interfaces/portal/stats-progress-bar';
import { TrafficListData } from '../../interfaces/portal/traffic-list';
import { UserActivityData } from '../../interfaces/portal/user-activity';

import { CountryOrderService } from './services/country-order.service';
import { OrderTypesService } from './services/order-types.service';
import { OrderStatusesService } from './services/order-statuses.service';
import { OrdersService } from './services/orders.service';
import { OrdersProfitChartService } from './services/orders-profit-chart.service';
import { ProfitBarAnimationChartService } from './services/profit-bar-animation-chart.service';
import { StatsBarService } from './services/stats-bar.service';
import { StatsProgressBarService } from './services/stats-progress-bar.service';
import { TrafficListService } from './services/traffic-list.service';
import { UserActivityService } from './services/user-activity.service';
import { PaymentConditionsService } from './services/payment-condition.service';
import { PaymentConditionData } from '../../interfaces/portal/payment-condition';
import { UnitMeasurementData } from '../../interfaces/portal/unit-measurement';
import { UnitMeasurementService } from './services/unit-measurement.service';
import { UnitMeasurementApi } from './api/unit-measurement.api';
import { CompanyApi } from './api/company.api';
import { CompanyData } from '../../interfaces/portal/company';
import { CompanyService } from './services/company.service';
import { CountyData } from '../../interfaces/common/county';
import { ProviderData } from '../../interfaces/portal/provider';
import { StateService } from './services/state.service';
import { CountyService } from './services/county.service';
import { ProviderService } from './services/provider.service';
import { ProviderApi } from './api/provider.api';
import { StateApi } from './api/state.api';
import { CountyApi } from './api/county.api';
import { StateData } from '../../interfaces/portal/state';
import { RequestData } from '../../interfaces/portal/request';
import { RequestService } from './services/request.service';
import { RequestApi } from './api/request.api';
import { ItemsApi } from './api/items.api';
import { ItemData } from '../../interfaces/portal/item';
import { ItemsService } from './services/items.service';
import { RequisitionItemApi } from './api/requisition-item';
import { RequisitionItemData } from '../../interfaces/portal/requisition-item';
import { RequisitionItemService } from './services/requisition-item.service';
import { QuoteData } from '../../interfaces/portal/quote';
import { QuoteService } from './services/quote.service';
import { QuoteApi } from './api/quote.api';
import { QuoteItemData } from '../../interfaces/portal/quote-item';
import { QuoteItemService } from './services/quote-item.service';
import { QuoteProviderData } from '../../interfaces/portal/quote-provider';
import { QuoteProviderApi } from './api/quote-provider.api';
import { QuoteProviderService } from './services/quote-provider.service';
import { QuoteProviderItemApi } from './api/quote-provider-item.api';
import { QuoteProviderItemData } from '../../interfaces/portal/quote-provider-item';
import { QuoteProviderItemService } from './services/quote-provider-item.service';
import { BankDataApi } from './api/bankData.api';
import { BankDataData } from '../../interfaces/portal/bankData';
import { BankDataService } from './services/bankData.service';
import { CoinService } from './services/coin.service';
import { CoinData } from '../../interfaces/portal/coin';
import { OrderItemData } from '../../interfaces/portal/orders-item';
import { OrdersItemService } from './services/orders-item.service';
import { OrdersItemApi } from './api/orders-item.api';
import { PaymentApi } from './api/payment.api';
import { PaymentService } from './services/payment.service';
import { PaymentData } from '../../interfaces/portal/payment';
import { ChatApi } from './api/chat.api';
import { ChatData } from '../../interfaces/portal/chatData';
import { ChatService } from '../../../pages/chat/chat.service';
import { ChatAPIService } from './services/chat-api.service';
import { BrandApi } from './api/brand.api';
import { BrandData } from '../../interfaces/portal/brands';
import { BrandService } from './services/brand.service';

const API = [
    OrderTypesApi,
    OrderStatusesApi,
    OrdersApi,
    OrdersItemApi,
    OrdersAggregatedApi,
    OrdersProfitApi,
    TrafficAggregatedApi,
    UserActivityApi,
    PaymentConditionApi,
    UnitMeasurementApi,
    CompanyApi,
    ProviderApi,
    StateApi,
    CountyApi,
    RequestApi,
    ItemsApi,
    BrandApi,
    RequisitionItemApi,
    QuoteApi,
    QuoteItemApi,
    QuoteProviderApi,
    QuoteProviderItemApi,
    BankDataApi,
    CoinApi,
    PaymentApi,
    ChatApi
];

const SERVICES = [
    { provide: CountryOrderData, useClass: CountryOrderService },
    { provide: OrderData, useClass: OrdersService },
    { provide: OrderItemData, useClass: OrdersItemService },
    { provide: OrderStatusData, useClass: OrderStatusesService },
    { provide: OrderTypeData, useClass: OrderTypesService },
    { provide: OrdersProfitChartData, useClass: OrdersProfitChartService },
    { provide: ProfitBarAnimationChartData, useClass: ProfitBarAnimationChartService },
    { provide: StatsBarData, useClass: StatsBarService },
    { provide: StatsProgressBarData, useClass: StatsProgressBarService },
    { provide: TrafficListData, useClass: TrafficListService },
    { provide: UserActivityData, useClass: UserActivityService },
    { provide: PaymentConditionData, useClass: PaymentConditionsService },
    { provide: RequestData, useClass: RequestService },
    { provide: UnitMeasurementData, useClass: UnitMeasurementService },
    { provide: CompanyData, useClass: CompanyService },
    { provide: StateData, useClass: StateService },
    { provide: CountyData, useClass: CountyService },
    { provide: ProviderData, useClass: ProviderService },
    { provide: ItemData, useClass: ItemsService },
    { provide: BrandData, useClass: BrandService },
    { provide: RequisitionItemData, useClass: RequisitionItemService },
    { provide: QuoteData, useClass: QuoteService },
    { provide: QuoteItemData, useClass: QuoteItemService },
    { provide: QuoteProviderData, useClass: QuoteProviderService },
    { provide: QuoteProviderItemData, useClass: QuoteProviderItemService },
    { provide: BankDataData, useClass: BankDataService },
    { provide: CoinData, useClass: CoinService },
    { provide: PaymentData, useClass: PaymentService },
    { provide: ChatData, useClass: ChatAPIService }]

@NgModule({
    imports: [CommonModule, CommonBackendModule],
    exports: [CommonBackendModule],
})
export class PortalBackendModule {
    static forRoot(): ModuleWithProviders<PortalBackendModule> {
        return {
            ngModule: PortalBackendModule,
            providers: [
                ...API,
                ...SERVICES,
            ],
        };
    }
}
