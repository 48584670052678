import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DefaultFilter } from 'ng2-smart-table';
import { NbCalendarRange } from '@nebular/theme';


@Component({
  template: `
    <input nbInput placeholder="{{column.title}}" class="form-control" [formControl]="inputControl" [nbDatepicker]="rangepicker">
    <nb-rangepicker #rangepicker></nb-rangepicker>
  `,
})
export class DateFilterComponent extends DefaultFilter implements OnInit, OnChanges {
  inputControl = new FormControl();

  constructor() {
    super();
  }

  ngOnInit() {
    this.inputControl.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(this.delay),
      )
      .subscribe((value: NbCalendarRange<Date>) => {
        if(this.inputControl.status === 'VALID')
        {
          this.query = value !== null ? JSON.stringify(this.inputControl.value) : "";
          this.setFilter();
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.query) {
      this.query = changes.query.currentValue;
      this.inputControl.setValue(this.inputControl.value);
    }
  }
}