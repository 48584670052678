import { Observable } from 'rxjs';
import { GridData } from '../common/gridData';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';

export interface Coin {
    id: number;
    codIntegration: number;
    name: string;
    symbol: string;
}

export abstract class CoinData {
  abstract get gridDataSource(): DataSource;
  abstract list(pageNumber: number, pageSize: number): Observable<GridData<Coin>>;
  abstract get(id: number): Observable<Coin>;
  abstract update(registeData: Coin): Observable<Coin>;
  abstract create(registeData: Coin): Observable<Coin>;
  abstract delete(id: number): Observable<boolean>;
}
