import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { HttpService } from '../../common/api/http.service';
import { Brands } from '../../../interfaces/portal/brands';

@Injectable()
export class BrandApi {
  private readonly apiController: string = 'brands';

  constructor(private api: HttpService) {}

  get usersDataSource(): DataSource {
    return this.api.getServerDataSource(this.apiController);
  }

  list(): Observable<Array<Brands>> {
    return this.api.get(this.apiController);
  }
}
