
      <div class="warning">
          <span class="caption status-danger"
             *ngIf="showMinLength"> {{ label }} requer no mínimo {{ minLength }} caracteres  
          </span>
          <span class="caption status-danger"
             *ngIf="showMaxLength"> {{ label }} permite no máximo {{ maxLength }} caracteres 
          </span>
          <span class="caption status-danger" *ngIf="showPattern"> {{ label }} está incorreto </span>
          <span class="caption status-danger" *ngIf="showRequired"> {{ label }} é obrigatório </span>
          <span class="caption status-danger" *ngIf="showMin">O valor mínimo para {{ label }} é {{ min }}</span>
          <span class="caption status-danger" *ngIf="showMax">O valor máximo para {{ label }} é {{ max }}</span>
      </div>
  